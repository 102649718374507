<template>
  <div class="warehouse-list-base-page">
    <div class="feature-form-container">
      <el-form
        :inline="true"
        :model="dataForm"
        ref="dataForm"
        class="bysearchForm"
      >
        <div class="searchLine">
          <el-form-item label="付款日期" class="typeInput">
            <el-date-picker
              v-model="payTime"
              class="el-popper DatepickerTime"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
            >
            </el-date-picker>
          </el-form-item>

          <el-button size="mini" @click="getDataList()">查询</el-button>
          <el-button
            @click="exportCorporateConsumption()"
            size="small"
            type="primary"
            >导出公司消费报表</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="corporate-wrap">
      <div class="corporate-detail-container">
        <div class="detail-container">
          <div class="table-container">
            <el-table :data="dataList" ref="dataList" border show-summary>
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="亚酒"
              />
              <el-table-column
                prop="goodsName"
                header-align="center"
                align="center"
                label="商品名称"
              />
              <el-table-column
                prop="goodsQuantity"
                header-align="center"
                sortable
                align="center"
                label="数量"
              />
              <el-table-column label="金额">
                <el-table-column
                  prop="subsidyUse"
                  header-align="center"
                  sortable
                  align="center"
                  label="补贴支付"
                />
                <el-table-column
                  prop="balanceUse"
                  header-align="center"
                  sortable
                  align="center"
                  label="余额支付"
                />
                <el-table-column
                  prop="wechatUse"
                  header-align="center"
                  sortable
                  align="center"
                  label="现金支付"
                />
                <el-table-column
                  prop="price"
                  header-align="center"
                  sortable
                  align="center"
                  label="小计"
                />
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="serial-container">
          <div class="table-container">
            <el-table :data="detailList" ref="detailList" border show-summary>
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="其他公司"
              />
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="商品名称"
              />
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="数量"
              />
              <el-table-column label="金额">
                <el-table-column
                  prop="name"
                  header-align="center"
                  sortable
                  align="center"
                  label="补贴支付"
                />
                <el-table-column
                  prop="name"
                  header-align="center"
                  sortable
                  align="center"
                  label="余额支付"
                />
                <el-table-column
                  prop="name"
                  header-align="center"
                  sortable
                  align="center"
                  label="现金支付"
                />
                <el-table-column
                  prop="name"
                  header-align="center"
                  sortable
                  align="center"
                  label="小计"
                />
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="divider-border" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { threeListMixin, normal } from '@/mixins';

export default {
  mixins: [threeListMixin, normal],
  data() {
    return {
      dataForm: {
        paidAtDatRang: '',
      },
      payTime: [],
      totalList: {
        employeesBalance: '',
        employeesMoney: '',
        dayBalance: '',
        monthBalance: '',
      },
      companyId: '',
      addOrUpdateVisible: false,
      employeesImportVisible: false,
    };
  },

  //注册需要引入的组件，即一个vue页面，这里指employees-add-or-update页面，

  activated() {
    this.getDataList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      // if (row.isUse === 0) return 'danger-row';
    },
    //获取数据列表
    getDataList() {
      if (this.payTime?.length) {
        this.dataForm.paidAtDatRang = this.payTime.join(',');
      }
      this.$http({
        url: '/tc/order/corporateConsumption',
        method: 'get',
        params: {
          ...this.dataForm,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          console.log(data.own);
          this.dataList = data.owns;
          this.detailList = data.others;
        } else {
          this.dataList = [];
        }
      });
    },

    exportCorporateConsumption() {
      if (this.payTime?.length) {
        this.dataForm.paidAtDatRang = this.payTime.join(',');
      }
      this.$http({
        url: `/tc/order/exportCorporateConsumption`,
        method: 'post',
        params: {
          ...this.dataForm,
        },
        responseType: 'blob',
      }).then(({ data }) => {
        if (data && data.size > 0) {
          const content = data;
          const blob = new Blob([content], {
            // 下载的文件格式自己在这边更改type的值就好了
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download =
              '公司消费报表_-_' + moment(new Date()).format('YYYYMMDDHHmmss');
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(
              blob,
              '公司消费报表_-_' + moment(new Date()).format('YYYYMMDDHHmmss'),
            );
          }
        }
        console.log(data);
      });
    },
  },
};
</script>

<style></style>
